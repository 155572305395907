var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lead-table"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.tableColumns,"rows":_vm.tableData,"totalRows":_vm.tablePagination.total,"pagination-options":{
      enabled: true,
      mode: 'pages',
      perPage: 30,
      perPageDropdown: _vm.perPageOptions,
      dropdownAllowAll: false
    },"sort-options":{
      enabled: false,
      initialSortBy: { field: 'updatedAt', type: 'desc' }
    },"isLoading":_vm.isLoading},on:{"on-page-change":function (val) {
        _vm.$emit('onPageChange, val');
      },"on-per-page-change":function (val) {
        _vm.$emit('onPerPageChange, val');
      },"on-row-click":function (ref) {
          var row = ref.row;

          return _vm.toDetails(row.id);
}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'leadPurpose')?_c('span',[_c('LeadPurpose',{attrs:{"purpose":props.row.purpose}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"No results for leads."}})],1),_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('spinner')],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }