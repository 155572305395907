<template>
  <div class="lead-table">
    <vue-good-table
      mode="remote"
      @on-page-change="
        (val) => {
          $emit('onPageChange, val');
        }
      "
      @on-per-page-change="
        (val) => {
          $emit('onPerPageChange, val');
        }
      "
      @on-row-click="({ row }) => toDetails(row.id)"
      :columns="tableColumns"
      :rows="tableData"
      :totalRows="tablePagination.total"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: perPageOptions,
        dropdownAllowAll: false
      }"
      :sort-options="{
        enabled: false,
        initialSortBy: { field: 'updatedAt', type: 'desc' }
      }"
      :isLoading="isLoading"
    >
      <!-- Empty State -->
      <div slot="emptystate">
        <no-data message="No results for leads."></no-data>
      </div>
      <!-- Loading State -->
      <div slot="loadingContent">
        <div class="d-flex justify-content-center">
          <spinner></spinner>
        </div>
      </div>
      <!-- Table Buttons -->
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'leadPurpose'">
          <LeadPurpose :purpose="props.row.purpose"></LeadPurpose>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    LeadPurpose: () => import("@/modules/Lead/components/LeadPurpose")
  },
  mixins: [vueGoodTable],
  props: {
    isLoading: {
      type: Boolean
    },
    tableColumns: {
      type: Array
    },
    tableData: {
      type: Array
    },
    tablePagination: {
      type: Object
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    toDetails(id) {
      this.$router.push({ name: "LeadDetails", params: { id: id } });
    }
  }
};
</script>

<style lang="scss"></style>
